import React, { memo, useEffect } from 'react';

import { saasApi } from '../../entities';

import { RegistrationLayout } from '../../layouts';

import { Loader, history } from '../../packages';
import { PathNames } from '../../consts';

import { ImageBox, InfoBox, WelcomeLabel, Wrapper } from './styled';
import { NotificationManager } from 'react-notifications';

export const PageConfirmation = memo(() => {
  const query = new URLSearchParams(window.location.search);
  const token = query.get('token');

  useEffect(() => {
    if (window.location.pathname.startsWith(PathNames.confirmationSaasAzure)) {
      saasApi
        .azureGignUp({ token })
        .then(() => {
          NotificationManager.success('Registration completed successfully');
        })
        .catch(() => {
          NotificationManager.error('Error. Registration incomplete.');
        })
        .finally(() => {
          history.replace(PathNames.features);
          history.go(0);
        });
    } else {
      NotificationManager.error('Error. Incorrect link.');
      history.replace(PathNames.home);
    }
  }, []);

  return (
    <RegistrationLayout>
      <Wrapper>
        <InfoBox>
          <WelcomeLabel>Confirmation...</WelcomeLabel>
        </InfoBox>
        <ImageBox>
          <Loader />
        </ImageBox>
      </Wrapper>
    </RegistrationLayout>
  );
});
