import { NotificationManager } from 'react-notifications';

import { FeaturePrefix, PathNames } from '../../consts';

import { history, modalActions, setAccessToken } from '../../packages';

import {
  OnboardingSteps,
  appActions,
  appApi,
  appSelectors,
  appService,
} from '../app';

import { customerActions, customerApi } from '../customer';

import { customerDetailsActions } from '../customer-details';

import { handleError } from '../../packages/utils/handleError';

import { authApi } from './api';

import { authActions } from './store';
import { authSelectors } from './selectors';
import { addPipelineModal } from './consts';

const signIn =
  (formModel, useOAuth = false) =>
  async (dispatch, getState) => {
    try {
      dispatch(authActions.setIsLoading(true));
      const query = new URLSearchParams(window.location.search);
      const token = query.get('token');
      const source = query.get('source');
      const redirect = query.get('redirect');

      const { access } = !useOAuth ? await authApi.signIn(formModel) : await authApi.applyOAuthCode(formModel);

      setAccessToken(access);

      await dispatch(appService.getUser());

      if (redirect && redirect.startsWith(window.location.origin)) {
        const route = decodeURIComponent(redirect);

        history.replace(route.replace(window.location.origin, ''));
        history.go(0);
        return;
      }

      await dispatch(appService.initialRequests());

      // TODO need move
      const selectedCustomer = await dispatch(appService.getSelectedCustomer());

      if (token) {
        const { menuItems } = await appSelectors.getAppData(getState());

        if (menuItems.length > 1) {
          dispatch(
            modalActions.setModalIsOpen({
              name: addPipelineModal,
              isOpen: true,
            })
          );

          return;
        }

        if (source === 'github') {
          await customerApi.addGithubPipelineAccount({
            installation_id: token,
            customer: selectedCustomer.id,
          });
          await dispatch(appService.activatePipelineSecurityFeature());
        } else {
          await customerApi.addAccount({
            uuid_token: token,
          });
          await dispatch(appService.activatePlatformSecurityFeature());
        }
      }

      NotificationManager.success('Successfully authenticated');

      if (selectedCustomer) {
        history.push({
          pathname: PathNames.home,
        });
      } else {
        NotificationManager.error(
          'You doesn\'t have company. Contact your administrator'
        );
        setAccessToken('');
      }
      // TODO need move ^
    } catch (e) {
      handleError(e);
    } finally {
      dispatch(authActions.setIsLoading(false));
    }
  };

const addPipelineAndRedirect = (customerId) => async (dispatch) => {
  try {
    if (customerId) {
      dispatch(authActions.setIsLoading(true));
      const query = new URLSearchParams(window.location.search);
      const token = query.get('token');
      const installation_id = query.get('installation_id');
      const source = query.get('source');

      if (source === 'github') {
        await customerApi.addGithubPipelineAccount({
          installation_id: token || installation_id,
          customer: customerId,
        });
      } else {
        await customerApi.addAccount({
          uuid_token: token,
          customer: customerId,
        });
      }

      dispatch(
        modalActions.setModalIsOpen({
          name: addPipelineModal,
          isOpen: false,
        })
      );
      NotificationManager.success('Pipeline has been successfully added');
      await dispatch(appService.initialRequests());

      if (source === 'github') {
        await dispatch(appService.activatePipelineSecurityFeature());
        history.push({
          pathname: PathNames.customerResults
            .replace(':feature', FeaturePrefix.PipelineSecurity)
            .replace(':id', customerId),
        });
      } else {
        await dispatch(appService.activatePlatformSecurityFeature());
        history.push({
          pathname: PathNames.customerResults
            .replace(':feature', FeaturePrefix.PlatformSecurity)
            .replace(':id', customerId),
        });
      }
    }
  } catch (e) {
    handleError(e);
  } finally {
    dispatch(authActions.setIsLoading(false));
  }
};

const registerByToken = (formModel) => async (dispatch) => {
  try {
    dispatch(authActions.setIsRegisterLoading(true));
    dispatch(authActions.setRegistrationEmail(formModel.email));

    const query = new URLSearchParams(window.location.search);
    const token = query.get('token');
    const source = query.get('source');

    const { detail, code } =
      source === 'github'
        ? await authApi.registerByTokenGithub(token, formModel)
        : await authApi.registerByToken(token, formModel);

    if (code === 'token_not_valid') {
      NotificationManager.error(detail);

      return;
    }

    NotificationManager.success(detail);

    dispatch(appActions.setOnboardingStep(OnboardingSteps.confirmEmail));
  } catch (e) {
    handleError(e);
  } finally {
    dispatch(authActions.setIsRegisterLoading(false));
  }
};

const register = (formModel) => async (dispatch) => {
  try {
    dispatch(authActions.setIsRegisterLoading(true));
    dispatch(authActions.setRegistrationEmail(formModel.email));

    const { detail } = await authApi.register(formModel);
    NotificationManager.success(detail);

    history.push({
      pathname: `${PathNames.verification}?email=${formModel.email}`,
    });
  } catch (e) {
    handleError(e);
  } finally {
    dispatch(authActions.setIsRegisterLoading(false));
  }
};

const resendCode = () => async (dispatch, getState) => {
  try {
    dispatch(authActions.setIsRegisterLoading(true));

    const { registrationEmail } = authSelectors.getAuthData(getState());

    const { detail } = await authApi.resendCode({
      email: registrationEmail,
    });

    NotificationManager.success(detail);

    dispatch(appActions.setOnboardingStep(OnboardingSteps.confirmEmail));
  } catch (e) {
    handleError(e);
  } finally {
    dispatch(authActions.setIsRegisterLoading(false));
  }
};

const confirmCode = (formModel, onConfirmAction) => async (dispatch) => {
  try {
    dispatch(authActions.setIsCodeSubmitting(true));

    const { access } = await authApi.verifyEmail(formModel);
    await setAccessToken(access);

    NotificationManager.success('Successfully confirmed');

    const user = await dispatch(appService.getUser());
    await dispatch(
      appService.updateCompany(user.company_id, { status: 'completed' })
    );
    await dispatch(appService.initialRequests());
    await dispatch(appService.getSelectedCustomer());

    if (onConfirmAction) {
      onConfirmAction();
    } else {
      dispatch(appActions.setOnboardingStep(OnboardingSteps.assignStandards));
    }
  } catch (e) {
    handleError(e);
  } finally {
    dispatch(authActions.setIsCodeSubmitting(false));
  }
};

const linkAuth = () => async (dispatch, getState) => {
  try {
    dispatch(authActions.setIsLoading(true));
    const query = new URLSearchParams(window.location.search);
    const token = query.get('token');
    const installation_id = query.get('installation_id');

    const source = query.get('source');
    try {
      const user = await appApi.getUserWithoutErrorCatcher();

      if (user) {
        await dispatch(appService.getUser());
        await dispatch(appService.initialRequests());
        const selectedCustomer = await dispatch(
          appService.getSelectedCustomer()
        );

        if (token || installation_id) {
          const { menuItems } = await appSelectors.getAppData(getState());

          if (menuItems.length > 1) {
            dispatch(
              modalActions.setModalIsOpen({
                name: addPipelineModal,
                isOpen: true,
              })
            );

            return;
          }

          if (source === 'github') {
            await customerApi.addGithubPipelineAccount({
              installation_id,
              customer: selectedCustomer.id,
            });
            history.push({
              pathname: PathNames.customerResults
                .replace(':feature', FeaturePrefix.PipelineSecurity)
                .replace(':id', selectedCustomer?.id),
            });
            await dispatch(appService.activatePipelineSecurityFeature());
          } else {
            await customerApi.addAccount({
              uuid_token: token,
            });
            await dispatch(appService.activatePlatformSecurityFeature());

            history.push({
              pathname: PathNames.customerResults
                .replace(':feature', FeaturePrefix.PlatformSecurity)
                .replace(':id', selectedCustomer?.id),
            });
          }
        }
      }
    } catch (e) {
      if (source === 'pipeline') {
        history.push({
          pathname: `${PathNames.pipelineOnboarding}?token=${token}&source=pipeline`,
        });

        return;
      }

      if (source === 'github') {
        history.push({
          pathname: `${PathNames.pipelineOnboarding}?token=${installation_id}&source=github`,
        });

        return;
      }

      const { access } = await authApi.linkAuth(token);
      setAccessToken(access);

      await dispatch(appService.getUser());

      history.push({
        pathname: PathNames.onboarding.replace(
          ':feature',
          FeaturePrefix.PlatformSecurity
        ),
      });
    }
  } catch (e) {
    console.error(e);
    NotificationManager.error('Token is not valid or expired');
  } finally {
    dispatch(authActions.setIsLoading(false));
  }
};

const getRegistrationData = () => async (dispatch) => {
  try {
    const query = new URLSearchParams(window.location.search);
    const token = query.get('token');
    const source = query.get('source');

    const registrationData =
      source === 'github'
        ? await authApi.getGithubRegistrationData(token)
        : await authApi.getRegistrationData(token);
    const { user_email } = registrationData;

    dispatch(authActions.setRegistrationData(registrationData));
    dispatch(authActions.setRegistrationEmail(user_email));

    return registrationData;
  } catch (e) {
    console.error(e);
    return {};
  } finally {
    dispatch(authActions.setIsLoading(false));
  }
};

const logout = () => async (dispatch) => {
  try {
    setAccessToken('');

    dispatch(customerActions.resetState());
    dispatch(customerDetailsActions.resetState());
    dispatch(appActions.setUser(null));
    dispatch(authActions.resetState());
    dispatch(appActions.setSearchValue(''));

    history.push({ pathname: PathNames.login });
  } catch (e) {
    console.error(e);
  }
};

export const authService = {
  signIn,
  logout,
  registerByToken,
  resendCode,
  register,
  linkAuth,
  confirmCode,
  getRegistrationData,
  addPipelineAndRedirect,
};
