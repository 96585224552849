import wretchInstance from 'wretch';

import { getAccessToken, setAccessToken } from '../../packages';

const BASE_URL = process.env.REACT_APP_BASE_URL || ''; // Connect to same domain, if not set another.

// Instantiate and configure wretch
export const wretch = (url, withoutCatcher) => {
  const token = getAccessToken();

  let instance = wretchInstance(`${BASE_URL}/aribot-api/v1/${url}`);

  if (token) {
    instance = instance.auth(`Bearer ${token}`);
  }

  if (withoutCatcher) {
    return instance;
  }

  return instance.catcher(401, (error) => {
    setAccessToken('');
    throw error;
  });
};

const getUserWithoutErrorCatcher = () =>
  wretch('customers/me/', true).get().json();

export const widgetBaseUrl =
  process.env.API_WIDGET_BASE_URL ?? 'https://dev.aribot.aristiun.com';

export const wretchppl = (url) =>
  wretchInstance(`${widgetBaseUrl}/pipeline-api/${url}`);

export const wretchInstallationAPI = (url) =>
  wretchInstance(`/installation-api/${url}`);

const wretchSassAPI = (url) => {
  let instance = wretchInstance(`/saas-api/${url}`);

  const token = getAccessToken();

  if (token) {
    instance = instance.auth(`Bearer ${token}`);
  }

  return instance;
};

export function prepareQuery(query) {
  const cont = {};
  Object.keys(query).forEach(key => {
    const val = query[key];
    if (val) {
      cont[key] = val;
    }
  });

  return cont;
}

const azureGignUp = (query) => wretchSassAPI(`azure/signup/`).query(query).get().json();

export const saasApi = {
  azureGignUp,
};

const getUser = () => wretch('customers/me/').get().json();

const updateUser = (id, requestModel) =>
  wretch(`user/${id}/`).put(requestModel).json();

const getProfile = () => wretch(`profile/`).get().json();

const updateProfile = (requestModel) =>
  wretch(`profile/`).put(requestModel).json();

const changePassword = (requestModel = { old_password: '', new_password: '' }) =>
  wretch(`profile/change-password/`).post(requestModel).json();

const uploadAvatar = (requestModel) =>
  wretch('profile/').formData(requestModel).put().json();

const getFeatures = () => wretch('features/').get().json();

const setFeatures = (requestModel) =>
  wretch('customers/me/set-features/').put(requestModel).json();

const updateCompany = (id, requestModel) =>
  wretch(`company/${id}/set_status/`).post(requestModel).json();

const getMenuItems = (query) => wretch('menu/').query(query).get().json();

const getPipelineData = ({ id, accountId }) =>
  wretch(`customers/${id}/accounts/${accountId}/creds/`).get().json();

const getPipelineDataPP = ({ ppToolToken }) =>
  wretchppl(`results/${ppToolToken}/creds/`).get().json();

export const appApi = {
  getUser,
  getProfile,
  changePassword,
  updateUser,
  updateProfile,
  uploadAvatar,
  getFeatures,
  setFeatures,
  updateCompany,
  getMenuItems,
  getPipelineData,
  getPipelineDataPP,
  getUserWithoutErrorCatcher,
};
